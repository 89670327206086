
import { defineComponent, reactive } from '@vue/composition-api'
import { mdiAlert } from '@mdi/js'

import { isValidDotNotationDate } from '@/utils/validation'

export default defineComponent({
  name: 'AssignmentDateInput',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
    hasHoursRecorded: {
      type: Boolean,
      default: false,
    },
  },
  setup: () => {
    return reactive({
      icons: {
        mdiAlert,
      },
      formRules: {
        isValidDotNotationDate,
      },
    })
  },
})
