import { ContractOutput } from '@/api/types/contract'
import { i18n } from '@/plugins/i18n'
import { convertCommaIntoDotInput, convertDotIntoCommaInput } from '@/utils/convertInput'

function getMinimumRelativeScope(contracts: ContractOutput[]): number {
  return Math.min(...contracts.filter((contract) => contract.scope).map((contract) => contract.scope))
}

function isInRelativeScope(value: string, contracts: ContractOutput[]): boolean | string {
  const valAsNumber = Number(convertCommaIntoDotInput(String(value)))
  const min = getMinimumRelativeScope(contracts)

  return valAsNumber <= min
    ? true
    : (i18n.t('form.validation.inRelativeScope', { value: convertDotIntoCommaInput(min) }) as string)
}

export { isInRelativeScope, getMinimumRelativeScope }
