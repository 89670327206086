
import { computed, defineComponent, PropType, reactive, ref, watch } from '@vue/composition-api'
import { cloneDeep } from 'lodash-es'

import CommonAddEditDialog from '@/components/common/CommonAddEditDialog.vue'
import CommonNumberInput from '@/components/common/CommonNumberInput.vue'
import AssignmentDateInput from '@/views/contractData/views/assignments/components/AssignmentDateInput.vue'
import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'

import {
  isBanfNr,
  isBanfOrderNr,
  isEndingDateBeforeStartingDate,
  isPercentageRange,
  isRequired,
} from '@/utils/validation'
import { convertCommaIntoDotInputWithForm, convertDotIntoCommaInputWithForm } from '@/utils/convertInput'
import {
  dateDotNotationToDashWithForm,
  dateDashNotationToDotWithForm,
  dateDashNotationToDot,
} from '@/utils/convertDate'
import { handleError } from '@/utils/handleError'
import { mapBasicEntityToIdWithForm } from '@/utils/mapBasicEntityToIdWithForm'
import { getMinimumRelativeScope, isInRelativeScope } from '@/views/contractData/views/assignments/utils'

import { useGetPersonsBasic } from '@/api/person'
import { useGetProfUnitsBasic } from '@/api/profUnit'
import { useGetContracts, useGetContractsBasic } from '@/api/contract'
import { useGetProfilesBasic } from '@/api/profile'
import { useGetDemandBasic } from '@/api/demand'
import { useCreateAssignment, useUpdateAssignment } from '@/api/assignment'
import { useGetWorkPlacesBasic } from '@/api/workPlace'
import { useGetTendering, useGetTenderingsBasic } from '@/api/tendering'
import { useGetPosition, useGetPositionsBasic } from '@/api/position'

import { DATA_TYPE, FormField, FORM_FIELDS_ENUM } from '@/utils/types/formField'
import { isOfType } from '@/utils/types/isOfType'
import { AssignmentOutput, AssignmentInput } from '@/api/types/assignment'
import { PROFILE_TYPE } from '@/api/types/profile'
import { PositionOutput } from '@/api/types/position'
import { ContractOutput } from '@/api/types/contract'
import { BasicEntity } from '@/api/types/misc'

export default defineComponent({
  name: 'AddEditAssignmentDialog',
  components: {
    CommonAddEditDialog,
    CommonNumberInput,
    AssignmentDateInput,
    CommonAutocomplete,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    assignmentToEdit: {
      type: Object as PropType<AssignmentOutput | null>,
      default: null,
    },
    assignmentToDuplicate: {
      type: Object as PropType<AssignmentOutput | null>,
      default: null,
    },
    position: {
      type: Object as PropType<PositionOutput | null>,
      default: null,
    },
  },
  setup: (props, { root, emit }) => {
    const personId = ref(root.$route.params.id)

    const isEditMode = computed(() => Boolean(props.assignmentToEdit))

    const assignmentToCreate =
      props.assignmentToDuplicate !== null ? props.assignmentToDuplicate : ({} as AssignmentInput)

    const form = ref<AssignmentInput | AssignmentOutput>(
      isEditMode.value && props.assignmentToEdit ? cloneDeep(props.assignmentToEdit) : assignmentToCreate
    )
    personId.value && assignPersonIdToForm()

    const { exec: getPersonsBasic, data: persons, isLoading: isLoadingPersonsBasic } = useGetPersonsBasic()
    getPersonsBasic()

    const { exec: getProfUnitsBasic, data: profUnits, isLoading: isLoadingProfUnitsBasic } = useGetProfUnitsBasic()
    getProfUnitsBasic()

    const { exec: getDemandsBasic, data: demands, isLoading: isLoadingDemandsBasic } = useGetDemandBasic()
    getDemandsBasic()

    const {
      exec: getTenderingsBasic,
      data: tenderings,
      isLoading: isLoadingTenderingsBasic,
    } = useGetTenderingsBasic()

    const { getTendering, data: tendering } = useGetTendering()

    const {
      exec: getContractsBasic,
      data: contractsBasic,
      isLoading: isLoadingContractsBasic,
    } = useGetContractsBasic()

    const { exec: getContracts, data: contracts } = useGetContracts()

    watch(
      () => form.value.person,
      () => {
        if (!form.value.person) return
        const formWithIds = mapBasicEntityToIdWithForm(form.value)

        getContractsBasic({ params: { personIds: formWithIds.person, size: 9999 } })
      },
      {
        immediate: true,
      }
    )

    // after seleection of contractsBasic, get the contracts for prefill
    watch(
      () => form.value.contracts,
      async (newVal, oldVal) => {
        if (!form.value.contracts?.length) return

        if (newVal.length !== oldVal?.length) {
          await getContracts({ params: { ids: form.value.contracts.join(','), size: 9999 } })
        }
      }
    )

    const { getPosition, data: position } = useGetPosition()

    watch(
      () => form.value.position,
      async () => {
        if (!form.value.position || isOfType<BasicEntity>(form.value, 'id')) return

        await getPosition(Number(form.value.position))

        if (!position.value) return

        form.value.profile = position.value.profile.id
        form.value.professionalUnit = position.value.professionalUnit.id
        form.value.scope = position.value.availableScopeForAssignment
        form.value.start = position.value.start
        form.value.end = position.value.end

        form.value = dateDashNotationToDotWithForm(FORM_FIELDS, form.value)
        form.value = convertDotIntoCommaInputWithForm(FORM_FIELDS, form.value)
      },
      {
        immediate: true,
      }
    )

    // prefill contract data
    watch(
      () => contracts.value,
      () => {
        calculateEarliestStartingContract()
        calculateLatestEndingContract()

        form.value = calcScopeBySelectedContractsAndUpdateForm()

        form.value = dateDashNotationToDotWithForm(FORM_FIELDS, form.value)
      }
    )

    // prefill tendering or filter dropdown
    watch(
      () => form.value.demand,
      async () => {
        if (form.value.demand || !isOfType<BasicEntity>(form.value, 'id')) {
          const formWithIds = mapBasicEntityToIdWithForm(form.value)

          await getTenderingsBasic({ params: { demandIds: formWithIds.demand, size: 9999 } })
        } else {
          await getTenderingsBasic({ params: { size: 9999 } })
        }

        if (tenderings.value && tenderings.value.length === 1) {
          form.value.tendering = tenderings.value[0]

          form.value = mapBasicEntityToIdWithForm(form.value)
        }
      },
      {
        immediate: true,
      }
    )

    // get tendering details after selection
    watch(
      () => form.value.tendering,
      async () => {
        if (!form.value.tendering || isOfType<BasicEntity>(form.value, 'id')) return

        await getTendering(Number(form.value.tendering))

        if (!tendering.value?.demand) return

        form.value.demand = tendering.value.demand

        form.value = mapBasicEntityToIdWithForm(form.value)
      },
      {
        immediate: true,
      }
    )

    const { exec: getPositionsBasic, data: positions, isLoading: isLoadingPositionsBasic } = useGetPositionsBasic()
    getPositionsBasic({
      params: { positionStatuses: 'ACTIVE', approved: true, hasRemainingScopeForAssignment: true },
    })
    const mergedPositions = computed(() => {
      if (props.assignmentToEdit) {
        return [props.assignmentToEdit.position, ...(positions.value ?? [])]
      } else {
        return positions.value ?? []
      }
    })

    const { exec: getProfilesBasic, data: profiles, isLoading: isLoadingProfilesBasic } = useGetProfilesBasic()
    getProfilesBasic({ params: { type: PROFILE_TYPE.ASSIGNMENT } })

    const {
      exec: getWorkplacesBasic,
      data: workPlaces,
      isLoading: isLoadingWorkPlacesBasic,
    } = useGetWorkPlacesBasic()
    getWorkplacesBasic()

    const DEFAULT_FORM_FIELDS: FormField[] = [
      {
        value: 'position',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => mergedPositions.value),
        isLoading: computed(() => isLoadingPositionsBasic.value),
        isRequired: false,
        rules: [],
        dropdownTextProp: 'name',
      },
      {
        value: 'professionalUnit',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => profUnits.value ?? []),
        isLoading: computed(() => isLoadingProfUnitsBasic.value),
        isRequired: true,
        rules: [
          (value: string) => isRequired(value, root.$t('contractData.assignments.form.professionalUnit') as string),
        ],
        dropdownTextProp: 'name',
      },
      {
        value: 'demand',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => demands.value ?? []),
        isLoading: computed(() => isLoadingDemandsBasic.value),
        isRequired: false,
        rules: [],
        dropdownTextProp: 'name',
      },
      {
        value: 'profile',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => profiles.value ?? []),
        isLoading: computed(() => isLoadingProfilesBasic.value),
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('contractData.assignments.form.profile') as string)],
        dropdownTextProp: 'name',
      },
      {
        value: 'tendering',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => tenderings.value ?? []),
        isLoading: computed(() => isLoadingTenderingsBasic.value),
        isRequired: false,
        rules: [],
        dropdownTextProp: 'name',
      },
      {
        value: 'contracts',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => contractsBasic.value ?? []),
        isLoading: computed(() => isLoadingContractsBasic.value),
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('contractData.assignments.form.contracts') as string)],
        dropdownTextProp: 'name',
        fieldOptions: [{ multiple: true }],
      },
      {
        value: 'start',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.DATE,
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('contractData.assignments.form.start') as string)],
      },
      {
        value: 'end',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.DATE,
        isRequired: true,
        rules: [
          (value: string) => isRequired(value, root.$t('contractData.assignments.form.end') as string),
          (value: string) => (form.value.start ? isEndingDateBeforeStartingDate(form.value.start, value) : true),
        ],
      },
      {
        value: 'workplace',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => workPlaces.value ?? []),
        isLoading: computed(() => isLoadingWorkPlacesBasic.value),
        isRequired: false,
        rules: [],
        dropdownTextProp: 'name',
      },
      {
        value: 'scope',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.NUMBER,
        isRequired: true,
        rules: [
          (value: string) => isRequired(value, root.$t('contractData.assignments.form.scope') as string),
          (value: string) => isPercentageRange(value),
          (value: string) => isInRelativeScope(value ? value : '', contracts.value),
        ],
      },
      {
        value: 'banfNr',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.NUMBER,
        isRequired: false,
        rules: [(value: string) => isBanfNr(value)],
      },
      {
        value: 'banfOrderNr',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.NUMBER,
        isRequired: false,
        rules: [(value: string) => isBanfOrderNr(value)],
      },
      {
        value: 'hoursOrdered',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.NUMBER,
        isRequired: false,
        rules: [],
      },
    ]

    // person ID will be set implicitly if personId is given
    const FORM_FIELDS: FormField[] = [
      ...(!personId.value
        ? [
            {
              value: 'person',
              fieldType: FORM_FIELDS_ENUM.DROPDOWN,
              items: computed(() => persons.value ?? []),
              isLoading: computed(() => isLoadingPersonsBasic.value),
              isRequired: true,
              rules: [
                (value: string) => isRequired(value, root.$t('contractData.assignments.form.person') as string),
              ],
              dropdownTextProp: 'name',
            },
          ]
        : []),
      ...DEFAULT_FORM_FIELDS,
    ]

    const { createAssignment, isLoading: isLoadingCreateAssignment } = useCreateAssignment()

    function assignPersonIdToForm(): void {
      form.value.person = Number(personId.value)
    }

    async function onAdd(): Promise<void> {
      if (!isOfType<AssignmentOutput>(form.value, 'id')) {
        let updatedForm = mapBasicEntityToIdWithForm(form.value)

        updatedForm = dateDotNotationToDashWithForm(FORM_FIELDS, updatedForm)

        updatedForm = convertCommaIntoDotInputWithForm(FORM_FIELDS, updatedForm)

        try {
          await createAssignment(updatedForm)
          close()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const { updateAssignment, isLoading: isLoadingUpdateAssignment } = useUpdateAssignment()

    async function onEdit(): Promise<void> {
      if (isOfType<AssignmentOutput>(form.value, 'id')) {
        let updatedForm = mapBasicEntityToIdWithForm(form.value)

        updatedForm = dateDotNotationToDashWithForm(FORM_FIELDS, updatedForm)

        updatedForm = convertCommaIntoDotInputWithForm(FORM_FIELDS, updatedForm)

        try {
          await updateAssignment(updatedForm.id, updatedForm)
          close()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const isLoadingAddEditAssignment = computed(
      () => isLoadingCreateAssignment.value || isLoadingUpdateAssignment.value
    )

    watch(
      () => {
        props.assignmentToEdit
        props.assignmentToDuplicate
      },
      () => {
        if (props.assignmentToEdit) {
          form.value = props.assignmentToEdit

          form.value = dateDashNotationToDotWithForm(FORM_FIELDS, form.value)

          form.value = convertDotIntoCommaInputWithForm(FORM_FIELDS, form.value)
        }
        if (props.assignmentToDuplicate) {
          form.value = props.assignmentToDuplicate

          form.value = dateDashNotationToDotWithForm(FORM_FIELDS, form.value)

          form.value = convertDotIntoCommaInputWithForm(FORM_FIELDS, form.value)
        }
      },
      {
        immediate: true,
      }
    )

    watch(
      () => props.position,
      () => {
        if (props.position) {
          form.value.position = props.position.id
        }
      },
      {
        immediate: true,
      }
    )

    function calculateEarliestStartingContract(): void {
      let earliestStartingContract: ContractOutput = {} as ContractOutput

      let currentDiffInMs = Number.MAX_SAFE_INTEGER

      contracts.value.forEach((contract: ContractOutput) => {
        if (new Date(contract.start).getTime() < currentDiffInMs) {
          currentDiffInMs = new Date(contract.start).getTime()

          earliestStartingContract = contract
        }
      })

      form.value.start = earliestStartingContract.start
    }

    function calculateLatestEndingContract(): void {
      let latestEndingContract: ContractOutput = {} as ContractOutput

      let currentDiffInMs = Number.MIN_SAFE_INTEGER

      contracts.value.forEach((contract: ContractOutput) => {
        if (new Date(contract.end).getTime() > currentDiffInMs) {
          currentDiffInMs = new Date(contract.end).getTime()
          latestEndingContract = contract
        }
      })

      form.value.end = latestEndingContract.end
    }

    function calcScopeBySelectedContractsAndUpdateForm(): AssignmentInput {
      return convertDotIntoCommaInputWithForm(FORM_FIELDS, {
        ...form.value,
        scope: Number.isFinite(getMinimumRelativeScope(contracts.value))
          ? getMinimumRelativeScope(contracts.value)
          : null,
      }) as AssignmentInput
    }

    function close(): void {
      emit('added-edited')
      emit('close')
    }

    const hasHoursRecorded = ref<boolean>(false)

    function onCheckHasHoursRecorded(assignmentToEdit: AssignmentOutput): void {
      if (isEditMode.value && assignmentToEdit.hasHoursRecorded) {
        hasHoursRecorded.value = true
      }
    }

    const doesPositionPrefillChange = ref<boolean>(false)

    function onPositionPrefillChange(property: string): void {
      if (!form.value.position || !position.value) return

      switch (form.value[property]) {
        case position.value.professionalUnit.id: {
          doesPositionPrefillChange.value = true
          break
        }
        case position.value.availableScopeForTransaction: {
          doesPositionPrefillChange.value = true
          break
        }
        case position.value.profile.id: {
          doesPositionPrefillChange.value = true
          break
        }
        case dateDashNotationToDot(position.value.start): {
          doesPositionPrefillChange.value = true
          break
        }
        case dateDashNotationToDot(position.value.end): {
          doesPositionPrefillChange.value = true
          break
        }
        default:
          doesPositionPrefillChange.value = false
          break
      }
    }

    return reactive({
      constants: {
        FORM_FIELDS_ENUM,
        DATA_TYPE,

        FORM_FIELDS,
      },
      state: {
        isEditMode,

        form,

        isLoadingAddEditAssignment,

        hasHoursRecorded,

        doesPositionPrefillChange,
      },
      listeners: {
        onAdd,
        onEdit,

        onCheckHasHoursRecorded,
        onPositionPrefillChange,
      },
      functions: {
        formRules: {
          isRequired,
        },
      },
    })
  },
})
