var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-text-field', _vm._g(_vm._b({
    class: _vm.hasHoursRecorded ? 'warn' : '',
    attrs: {
      "value": _vm.value,
      "hint": _vm.hasHoursRecorded ? _vm.$t('form.validation.hasHoursRecorded') : 'DD.MM.YYYY',
      "color": _vm.hasHoursRecorded ? 'warning' : undefined,
      "persistent-hint": _vm.hasHoursRecorded ? true : false,
      "rules": [].concat(_vm.rules, [_vm.formRules.isValidDotNotationDate(_vm.value)])
    },
    scopedSlots: _vm._u([_vm.hasHoursRecorded ? {
      key: "append",
      fn: function fn() {
        return [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiAlert) + " ")])];
      },
      proxy: true
    } : null], null, true)
  }, 'v-text-field', _vm.$attrs, false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }